import request from '@/utils/request'

// 查询客户通用套餐
export function findBaseProducts(params) {
  return request({
    url: `/base_products`,
    method: 'get',
    params: params
  })
}

// 创建客户通用套餐
export function createBaseProduct(data) {
  return request({
    url: `/base_products`,
    method: 'post',
    data
  })
}

// 查询单个客户通用套餐表单值
export function findBaseProductForm(id) {
  return request({
    url: `/base_products/${id}/form`,
    method: 'get'
  })
}

// 更新客户通用套餐
export function updateBaseProduct(id, data) {
  return request({
    url: `/base_products/${id}`,
    method: 'put',
    data
  })
}

// 批量更新客户通用套餐有效性
export function batchUpdateBaseProductsEffective(ids, effective) {
  const data = { ids: ids, effective: effective }
  return request({
    url: `/base_products/effective/batch`,
    method: 'patch',
    data
  })
}

// 批量删除客户通用套餐
export function deleteBaseProducts(ids) {
  return request({
    url: `/base_products/batch`,
    method: 'delete',
    data: ids
  })
}

// 查询单个通用套餐
export function findBaseProduct(id) {
  return request({
    url: `/base_products/${id}`,
    method: 'get'
  })
}

// 查询可导入的客户通用套餐
export function findImportableBaseProducts(params) {
  return request({
    url: `/base_products/importable`,
    method: 'get',
    params: params
  })
}

// 查询单个通用套餐的关联客户
export function findBaseProductAgents(id) {
  return request({
    url: `/base_products/${id}/agents`,
    method: 'get'
  })
}

// 导出客户通用套餐
export function exportBaseProducts(data) {
  return request({
    url: `/base_products/export`,
    method: 'post',
    data
  })
}
