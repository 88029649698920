<template>
  <div>
    <search-base-product @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
          v-if="isShowMenu('base_products/new')"
        >新增通用套餐</a-button>

        <a-button
          type="primary"
          @click="showNewAddonModal"
          v-if="isShowMenu('base_products/new')"
        >新增通用加油包</a-button>

        <update-base-product-effective
          v-if="isShowMenu('base_products/effective')"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />

        <destroy-base-product
          v-if="isShowMenu('base_products/delete') && false"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />

        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <div slot="name" slot-scope="name, record">
        <div v-if="isShowMenu('base_products/show')">
          <a @click="showInfoModal(record.id)">{{ name }}</a>
        </div>
        <div v-else> {{ name }} </div>
      </div>

      <div slot="agents_count" slot-scope="agents_count, record">
        <div v-if="agents_count > 0 && isShowMenu('base_products/agents')">
          <a @click="showAgentsModal(record.id)">{{ agents_count }}</a>
        </div>
        <div v-else> {{ agents_count }} </div>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="isHasEditPermission">
              <a @click="showEditModal(record.product_type_slug, record.id)">编辑</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="showOperationHistoryModal(record.id)">操作历史</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增通用套餐模态框 -->
    <new-base-product
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑通用套餐模态框 -->
    <edit-base-product
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <!-- 详情通用套餐模态框 -->
    <show-base-product
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />

    <!-- 新增加油包模态框 -->
    <new-base-addon
      v-if="isShowNewAddonModal"
      :visible.sync="isShowNewAddonModal"
      @completed="fetchData"
    />

    <!-- 编辑加油包模态框 -->
    <edit-base-addon
      v-if="isShowEditAddonModal"
      :visible.sync="isShowEditAddonModal"
      :id="editingId"
      @completed="fetchData"
    />

    <!-- 关联客户模态框 -->
    <base-product-agents
      v-if="isShowAgentsModal"
      :visible.sync="isShowAgentsModal"
      :id="showingId"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="BaseProduct"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findBaseProducts, exportBaseProducts } from '@/api/base_product'
import SearchBaseProduct from '@/views/base_products/Search'
import Pagination from '@/components/Pagination'
import { formatFloat } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'BaseProductList',
  components: {
    Pagination,
    SearchBaseProduct,
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal'),
    NewBaseProduct: () => import('@/views/base_products/New'),
    EditBaseProduct: () => import('@/views/base_products/Edit'),
    UpdateBaseProductEffective: () => import('@/views/base_products/UpdateEffective'),
    DestroyBaseProduct: () => import('@/views/base_products/Destroy'),
    ShowBaseProduct: () => import('@/views/base_products/Show'),
    BaseProductAgents: () => import('@/views/base_products/Agents'),
    NewBaseAddon: () => import('@/views/base_products/AddonNew'),
    EditBaseAddon: () => import('@/views/base_products/AddonEdit')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增套餐表单弹窗
      isShowEditModal: false, // 是否显示编辑套餐表单弹窗
      isShowInfoModal: false, // 是否显示详情套餐弹窗
      isShowNewAddonModal: false, // 是否显示新增加油包表单弹窗
      isShowEditAddonModal: false, // 是否显示编辑加油包表单弹窗
      isShowAgentsModal: false, // 是否显示关联客户弹窗
      isShowOperationHistoryModal: false,
      showingOperationHistoryId: 0,
      editingId: 0, // 正在编辑的记录id
      showingId: 0,
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: {
        effective: '1'
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '套餐ID',
          dataIndex: 'code',
          fixed: 'left'
        },
        {
          title: '套餐名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          fixed: 'left'
        },
        {
          title: '套餐类型',
          dataIndex: 'product_type',
          scopedSlots: { customRender: 'product_type' },
          fixed: 'left'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '流量属性',
          dataIndex: 'traffic_property'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '是否零售',
          dataIndex: 'is_allow_retail'
        },
        {
          title: '套餐周期',
          dataIndex: 'service_cycle'
        },
        {
          title: '套餐容量',
          dataIndex: 'package_capacity'
        },
        {
          title: '语音',
          dataIndex: 'voice_capacity'
        },
        {
          title: '标准价(元)',
          dataIndex: 'distributor_price',
          customRender: formatFloat
        },
        {
          title: '底价(元)',
          dataIndex: 'floor_price',
          customRender: formatFloat
        },
        {
          title: '套餐有效性',
          dataIndex: 'effective'
        },
        {
          title: '关联客户数',
          dataIndex: 'agents_count',
          scopedSlots: { customRender: 'agents_count' },
          sorter: true,
          sortOrder: this.sort.sort_field === 'agents_count' ? this.sort.sort_order : false
        },
        {
          title: '关联卡数(不含销卡)',
          dataIndex: 'cards_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cards_count' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    isHasEditPermission() {
      return hasPermission('base_products/edit')
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(productTypeSlug, recordId) {
      if (productTypeSlug === 'common') {
        this.isShowEditModal = true
      } else {
        this.isShowEditAddonModal = true
      }

      this.editingId = recordId
    },

    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    showNewAddonModal() {
      this.isShowNewAddonModal = true
    },

    showAgentsModal(recordId) {
      this.showingId = recordId
      this.isShowAgentsModal = true
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findBaseProducts(Object.assign({}, this.query, this.sort)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },

    exportData() {
      exportBaseProducts(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
